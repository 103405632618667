<template>
  <div class="aboutus con_area_bgcolor">
    <div class="aboutus_con con_area">
      <div class="w11402">
        <h2>
          <span class="title">About Us</span>
        </h2>
        <div>
          <el-tabs
            v-model="title"
            style="min-height: 300px"
            :tab-position="cWidth > 1024 ? 'left' : 'top'"
          >
            <el-tab-pane
              label="Introduction"
              name="Introduction"
              class="justify_align"
            >
              <p>
                Manawatu Chinese Community Trust (here after refer to as “The
                Trust”) is an incorporated charity trust which is set up to
                promote Chinese cultural identity and provide a comprehensive
                service to the entire Chinese communities in Manawatu region .
              </p>
              <p>
                The Trust is based on Palmerston North. It aims to build up
                Chinese networks; promote a great Chinese heritage; encourage
                the cultural and language maintenance; strengthen Chinese unity
                and mutual support; share fundamental cultural values,
                languages, traditions with the people from other cultures in New
                Zealand.
              </p>
              <p>
                It provides facilities, research and learning opportunities to
                the community enhancing their pleasant existence and the
                contributions to the multi-cultural civilization of New Zealand.
              </p>
              <p>
                We organise cultural events around the year in which Chinese
                culture is celebrated; Participate in various culture activities
                hosted by local city council, ethnic center and other community
                organisations in which the cultural diversity is promoted and
                where we learn from, enjoy and grow in understanding of each
                other.
              </p>
              <p>
                马那瓦图华人社区信托（以下简称“信托”）是一家旨在促进华人文化认同并为马那瓦土地区整个华人社区提供全面服务的法人慈善信托。<br />
              </p>
              <p>
                信托基金以北帕默斯顿为基地。它旨在建立中国网络；弘扬伟大的中国传统；鼓励保持文化和语言；加强中国的团结和相互支持；与新西兰其他文化的人分享基本的文化价值观、语言和传统。<br />
              </p>
              <p>
                它为社区提供了设施、研究和学习机会，增强了他们的愉快生活，并为新西兰的多元文化文明做出了贡献。<br />
              </p>
              <p>
                我们每年都会组织文化活动来庆祝中国文化；参加由当地市议会、民族中心和其他社区组织主办的各种文化活动，在这些活动中，文化多样性得到了促进，我们在相互学习、享受和了解中成长。
              </p>
            </el-tab-pane>
            <el-tab-pane label="Trustees" name="Trustees">
              <div class="trustees_con">
                <div v-for="(item, index) in imgList" :key="index">
                  <img
                    :src="require('@/assets/img/trustees/' + item.imgName)"
                    alt=""
                  />
                  <div class="trustees_con_item_introd">
                    <h2>{{ item.name }}</h2>
                    <h3>
                      <i class="iconfont icon-trustpilot"></i
                      >{{ item.position }}
                    </h3>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Contact Us" name="Contact Us">
              <div class="aboutus_tell_con">
                <div class="aboutus_tell">
                  <div>
                    <p>
                      <span class="iconfont icon-xianxingdianhua"></span>
                    </p>
                    <div>
                      <p>Chinese Hotline :</p>
                      <h3>+64 6 3562629</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <p>
                      <span class="iconfont icon-shouji"></span>
                    </p>
                    <div>
                      <p>Mobile phone :</p>
                      <h3>+64 27 8819818</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <p>
                      <span class="iconfont icon-iconfontunie62c"></span>
                    </p>
                    <div>
                      <p>Email :</p>
                      <h3>mccttrust@gmail.com</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <p>
                      <span class="iconfont icon-icon_weixin-logo"></span>
                    </p>
                    <div>
                      <p>Wechat :</p>
                      <h3>Wen631090</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <p>
                      <span class="iconfont icon-lianshu"></span>
                    </p>
                    <div>
                      <p>Facebook :</p>
                      <h3>Mcct Trust</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <p>
                      <span class="iconfont icon-yumingyuwangzhan"></span>
                    </p>
                    <div>
                      <p>Website :</p>
                      <h3>www.manawatuchinese.org.nz</h3>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="aboutus_tell_con">
                <div class="aboutus_tell">
                  <div>
                    <span class="iconfont icon-xianxingdianhua"></span>
                    <div>
                      <p>Chinese Hotline :</p>
                      <h3>+64 6 3562629</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <span class="iconfont icon-shouji"></span>
                    <div>
                      <p>Mobile phone :</p>
                      <h3>+64 27 8819818</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <span class="iconfont icon-iconfontunie62c"></span>
                    <div>
                      <p>Email :</p>
                      <h3>mccttrust@gmail.com</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <span class="iconfont icon-icon_weixin-logo"></span>
                    <div>
                      <p>Wechat :</p>
                      <h3>Wen631090</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <span class="iconfont icon-lianshu"></span>
                    <div>
                      <p>Facebook :</p>
                      <h3>Mcct Trust</h3>
                    </div>
                  </div>
                </div>
                <div class="aboutus_tell">
                  <div>
                    <span class="iconfont icon-yumingyuwangzhan"></span>
                    <div>
                      <p>Website :</p>
                      <h3>www.manawatuchinese.org.nz</h3>
                    </div>
                  </div>
                </div>
              </div> -->
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cWidth: sessionStorage.getItem("clientWidth"),
      title: this.$route.query.param,
      imgList: [
        {
          imgName: "1.jpg",
          name: "Wen Li",
          position: "Chairperson",
        },
        {
          imgName: "2.jpg",
          name: "Qian Yang",
          position: "Trustee",
        },
        {
          imgName: "3.jpg",
          name: "Johnson O",
          position: "Treasurer",
        },
        {
          imgName: "4.jpg",
          name: "Xiaoqi Wen",
          position: "Trustee",
        },
        {
          imgName: "5.jpg",
          name: "Bill Zhu",
          position: "Secretary",
        },
      ],
    };
  },
  created() {},
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.query.param) {
          //需要监听的参数
          this.title = this.$route.query.param;
        } else {
          this.title = "Introduction";
        }
      },
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style>
.aboutus {
  width: 100%;
}
.aboutus_con {
  width: 100%;
}

.aboutus_con .w11402 {
  /* width: 1140px; */
  margin: 0 auto;
  overflow: hidden;
}

/* 成员介绍 */
.trustees_con {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.trustees_con > div {
  width: 30%;
  /* margin-bottom: 2rem; */
  margin: 2rem 1.55%;
  overflow: hidden;
  border-radius: 3px;
  box-sizing: border-box;
  /* background-color: #f9f9f9; */
  /* box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.03); */
  /* box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1); */
}
.trustees_con > div > img {
  width: 100%;
  height: 330px;
  object-fit: cover;
  /* height: calc(100% - 80px); */
  /* height: auto; */
}
.trustees_con > div > .trustees_con_item_introd {
  /* width: 105%; */
  width: 100%;
  box-sizing: border-box;
  background: #01b5d1;
  color: #ffffff;
  text-transform: capitalize;
  position: relative;
  left: 0;
  top: -2px;
  padding: 10px 20px;
  letter-spacing: 1.5px;
  border-radius: 0 0 3px 3px;
}
.trustees_con > div > .trustees_con_item_introd > h2 {
  font-weight: normal;
  font-size: 2.6rem;
  /* height: 4rem; */
  line-height: 4rem;
}
.trustees_con > div > .trustees_con_item_introd > h3 {
  font-weight: normal;
  font-size: 2.2rem;
  /* height: 4rem; */
  line-height: 4rem;
}
.trustees_con > div > .trustees_con_item_introd > h3 > i {
  font-size: 2.2rem;
  margin-right: 0.5rem;
}
</style>